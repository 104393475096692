export const PhotoStatusCodes = {
  deletedByAdmin: 0,
  pending: 1,
  approved: 2,
  banned: 3,
  deletedByUser: 4,
  featured: 5,
  accepted: 6
}

export const baseUrl = window.location.origin

import { evolve, findIndex, propEq, remove, F, T } from 'ramda'

export const setIsLoading = (state, { isLoading }) => {
  return { ...state, isLoading }
}

export const setPhotos = (state, { photos }) => {
  return { ...state, photos }
}

export const enablePhoto = (state, { photoId }) => {
  const photoIdx = findIndex(propEq('photoid', photoId), state.photos)
  return evolve(
    {
      photos: {
        [photoIdx]: {
          disabled_by_user: F
        }
      }
    },
    state
  )
}

export const disablePhoto = (state, { photoId }) => {
  const photoIdx = findIndex(propEq('photoid', photoId), state.photos)
  return evolve(
    {
      photos: {
        [photoIdx]: {
          disabled_by_user: T
        }
      }
    },
    state
  )
}

export const deletePhoto = (state, { photoId }) => {
  const photoIdx = findIndex(propEq('photoid', photoId), state.photos)
  return evolve({ photos: remove(photoIdx, 1) }, state)
}

export const setLimit = (state, { limit }) => {
  return { ...state, limit }
}

export const setOffset = (state, { offset }) => {
  return { ...state, offset }
}

export const setTotalNumberOfPhotos = (state, { count }) => {
  return { ...state, totalNumberOfPhotos: count }
}

export const showFullpageLoading = () => {
  document.getElementById('fullpage-loading').classList.remove('hidden')
}

export const hideFullpageLoading = () => {
  document.getElementById('fullpage-loading').classList.add('hidden')
}

window.showFullpageLoading = showFullpageLoading
window.hideFullpageLoading = hideFullpageLoading

export default {
  isLoading: false,
  users: [],
  defaultReferral: null,
  sum: {
    isSelfReferencing: 0,
    hasNonUniqueReferralTree: 0,
    isReferringIntermediateCompanyUser: 0,
    isReferringInvalidOrZero: 0
  }
}

import { isNil, replace, assocPath } from 'ramda'
import { isPlainObj } from 'ramda-adjunct'
import { isBetween, prefixIfNotEmpty } from './functions.js'

// http://photogram/weboffice/api/users?limit=10&offset=0&exclude[]=2134617&exclude[]=12&exclude[]=10
export const toURI = (obj) => {
  return Object.entries(obj)
    .filter((kvPair) => {
      return !isNil(kvPair[1])
    })
    .reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        return [
          ...acc,
          ...value.map((val) => {
            return `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
          })
        ]
      } else if (isPlainObj(value)) {
        // TODO: ez ne stringify-olt JSON-nal működjön
        return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`]
      } else {
        return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`]
      }
    }, [])
    .join('&')
}

// "alma[sajt][bab]" -> ["alma", sajt", "bab"]
const formKeyToPath = (key) => {
  return key.split(/\]?\[/g).map(replace(']', ''))
}

export const formToJSON = (form) => {
  let obj = {}

  for (const [key, value] of new FormData(form).entries()) {
    obj = assocPath(formKeyToPath(key), value, obj)
  }

  return obj
}

export const xhrGet = async (url, params = {}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const translateEnabled = urlParams.get('translate')

  const query = prefixIfNotEmpty('?', toURI({ ...params, translate: translateEnabled && translateEnabled === 'true' }))
  const response = await fetch(`${url}${query}`)
  if (!isBetween(200, 300, response.status)) {
    throw new Error(`Failed to GET "${url}${query}"`)
  }
  return response.json()
}

export const xhrPost = async (url, data = {}) => {
  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: toURI(data)
  })
  if (!isBetween(200, 300, response.status)) {
    throw response
  }
  return response.json()
}

export const xhrPatch = async (url, data = {}) => {
  const response = await fetch(url, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: toURI(data)
  })
  if (!isBetween(200, 300, response.status)) {
    throw response
  }
  return response.json()
}

export const xhrDelete = async (url) => {
  const response = await fetch(url, {
    method: 'delete'
  })
  if (!isBetween(200, 300, response.status)) {
    throw new Error(`Failed to DELETE "${url}"`)
  }
  return response.json()
}

export const xhrPut = async (url, data = {}) => {
  const response = await fetch(url, {
    method: 'put',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: toURI(data)
  })
  if (!isBetween(200, 300, response.status)) {
    throw response
  }
  return response.json()
}

import { isEmpty } from 'ramda'

const isString = (x) => {
  return typeof x === 'string'
}

const prefixIfNotEmpty = (prefix, str) => {
  if (!isString(str) || isEmpty(str)) {
    return ''
  } else {
    return prefix + str
  }
}

const isBetween = (min, max, number) => {
  return number >= min && number < max
}

export { isString, prefixIfNotEmpty, isBetween }

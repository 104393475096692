export const setIsLoading = (state, { isLoading }) => {
  return { ...state, isLoading }
}

export const setPhotoId = (state, { photoId }) => {
  return { ...state, photoId }
}

export const setPhotoData = (state, { photoData }) => {
  return { ...state, photoData }
}

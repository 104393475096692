export default {
  isUnderPayment: false,
  isLoading: false,
  items: {
    services: [],
    sectors: [],
    photosForService: [],
    photosForSector: [],
    photos: []
  },
  settings: {}
}

export const setIsLoading = (state, { isLoading }) => {
  return { ...state, isLoading }
}

export const setUsers = (state, { users }) => {
  return { ...state, users }
}

export const setDefaultReferral = (state, { defaultReferral }) => {
  return { ...state, defaultReferral }
}

export const setSum = (state, { sum }) => {
  return { ...state, sum }
}

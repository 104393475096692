/* global i18n, Slim, eventBus */

import $ from 'jquery'
import { formToJSON, xhrPatch } from '../../helpers/http.js'
import { baseUrl } from '../../common/constants.js'

const attachSlim = (target, config = {}) => {
  // bizonyos funkciókat css-ből tiltunk le; keress rá, hogy .slim {
  if (!target) {
    console.warn('attachSlim: target not found')
    return
  }

  const { hideInlineSave } = config.extra

  delete config.extra

  // ---------------------------------------

  const createButton = (iconClass, title = '', buttonClass = '') => {
    const icon = document.createElement('i')
    icon.classList.add(...iconClass.trim().split(' '))

    const button = document.createElement('button')
    button.classList.add(...('slim-btn ' + buttonClass).trim().split(' '))
    button.type = 'button'
    button.appendChild(icon)

    if (title) {
      button.setAttribute('title', title)
    }

    return button
  }

  const show = (element) => {
    element.classList.add('visible')
  }

  const hide = (element) => {
    element.classList.remove('visible')
  }

  // ---------------------------------------

  const buttons = {}
  const state = {
    eventPreventer: {
      didTransform: false
    },
    firstEvent: {
      didLoad: true
    }
  }

  const cropper = new Slim(target, {
    ...config,
    label: ' ',
    didRemove: () => {
      show(buttons.open)
      if (hideInlineSave) {
        $('#profileUpdateSubmit').prop('disabled', false)
      } else {
        hide(buttons.upload)
      }
      hide(buttons.remove)
      hide(buttons.edit)
    },
    didTransform: () => {
      if (state.eventPreventer.didTransform) {
        state.eventPreventer.didTransform = false
        return
      }

      show(buttons.remove)
      show(buttons.edit)
      hide(buttons.open)
      if (hideInlineSave) {
        $('#profileUpdateSubmit').prop('disabled', false)
      } else {
        show(buttons.upload)
      }
    },
    didLoad: () => {
      state.eventPreventer.didTransform = true

      if (cropper._data.input.name === 'avatar.png') {
        show(buttons.open)
        if (!hideInlineSave) {
          hide(buttons.upload)
        }
        hide(buttons.remove)
        hide(buttons.edit)
      } else {
        let hasImageInitially = false
        if (state.firstEvent.didLoad) {
          hasImageInitially = cropper._data.input.file
        }

        show(buttons.remove)
        show(buttons.edit)
        hide(buttons.open)

        if (!hideInlineSave) {
          if (hasImageInitially) {
            hide(buttons.upload)
          } else {
            show(buttons.upload)
          }
        }
      }

      return true
    },
    didInit: () => {
      // az init event a load után fut le, ami nekünk itt most kivételesen jó
      state.firstEvent.didLoad = false
    },
    buttonRemoveLabel: "<i class='fas fa-trash' title='" + i18n.t('btn_label_delete') + "'></i>",
    buttonEditLabel: "<i class='fas fa-pencil-alt' title='" + i18n.t('btn_label_edit') + "'></i>",
    buttonCancelLabel: i18n.t('global_cancel'),
    buttonConfirmLabel: i18n.t('global_save')
  })

  buttons.edit = cropper._btnGroup.querySelector('.slim-btn-edit')
  buttons.remove = cropper._btnGroup.querySelector('.slim-btn-remove')
  buttons.open = createButton('fas fa-folder-open', i18n.t('btn_label_open'))
  if (!hideInlineSave) {
    buttons.upload = createButton('far fa-save', i18n.t('btn_label_save_and_upload'), 'red')
  }

  cropper._btnGroup.appendChild(buttons.open)
  if (!hideInlineSave) {
    cropper._btnGroup.appendChild(buttons.upload)
  }

  buttons.open.addEventListener('click', () => {
    cropper._imageHopper._element.click()
  })

  if (!hideInlineSave) {
    buttons.upload.addEventListener('click', async () => {
      try {
        await config.onUpdate(cropper)
        hide(buttons.upload)
      } catch (e) {
        console.error(e)
      }
    })
  }

  // az init event a load után fut le, ami itt viszont rossz
  show(buttons.open)
  if (!hideInlineSave) {
    hide(buttons.upload)
  }
  hide(buttons.remove)
  hide(buttons.edit)

  if (hideInlineSave) {
    target
      .querySelector('input[type="hidden"]')
      .setAttribute('form', window.location.pathname.startsWith(`/profile/myprofile`) ? 'updateBlog' : 'updateprofile')
  }
}

// -----------------

const init = (form, options = {}) => {
  attachSlim(form.querySelector('.bannerimage'), {
    onUpdate: async () => {
      const data = formToJSON(form)

      return xhrPatch(`${baseUrl}/web/profile`, {
        bannerimage: JSON.parse(data.bannerimage).output
      })
    },
    forceSize: '1217,388',
    ratio: '1217:388',
    extra: {
      hideInlineSave: options.narrow
    }
  })

  attachSlim(form.querySelector('.avatarimage'), {
    onUpdate: async () => {
      const data = formToJSON(form)

      return xhrPatch(`${baseUrl}/web/profile`, {
        avatarimage: JSON.parse(data.avatarimage).output
      })
    },
    forceSize: '300,300',
    ratio: '1:1',
    extra: {
      hideInlineSave: options.narrow
    }
  })
}

const wrapper = document.getElementById('banner-and-avatar')
if (wrapper) {
  const form = wrapper.querySelector('form')
  const isNarrowVersion = wrapper.classList.contains('narrow')
  if (form) {
    if (i18n.loaded) {
      init(form, {
        narrow: isNarrowVersion
      })
    } else {
      eventBus.once('system:translations-loaded', () => {
        init(form, {
          narrow: isNarrowVersion
        })
      })
    }
  }
}

const wrapper2 = document.getElementById('avatar')
if (wrapper2) {
  const form2 = wrapper2.querySelector('form')
  const isNarrowVersion2 = wrapper2.classList.contains('narrow')
  if (form2) {
    if (i18n.loaded) {
      init(form2, {
        narrow: isNarrowVersion2
      })
    } else {
      eventBus.once('system:translations-loaded', () => {
        init(form2, {
          narrow: isNarrowVersion2
        })
      })
    }
  }
}

/* global QRCode, mainState, i18n, notify */
/* global grecaptcha, InputSetValidity */

import $ from 'jquery'
import 'bootstrap'
import 'bootstrap-switch'
import './banner-and-avatar.js'
import { pluck, join, filter, has, propEq, isNil } from 'ramda'
// import SmartBanner from 'smart-app-banner/dist/smart-app-banner.js'
import moment from 'moment'
import { formToJSON, xhrPost, toURI } from '../../helpers/http.js'
import { baseUrl } from '../../common/constants.js'
import { showFullpageLoading } from './common.js'
import '@chenfengyuan/datepicker/dist/datepicker.min.js'
import '@chenfengyuan/datepicker/dist/datepicker.min.css'

// TODO: ezeket lehetne dinamikusan importálni is
// illetve csak azt a nyelvi fájl, ami a state-ben van
import '@chenfengyuan/datepicker/i18n/datepicker.ru-RU.js'
import '@chenfengyuan/datepicker/i18n/datepicker.hu-HU.js'
import '@chenfengyuan/datepicker/i18n/datepicker.en-GB.js'
import '@chenfengyuan/datepicker/i18n/datepicker.es-ES.js'
import '@chenfengyuan/datepicker/i18n/datepicker.pt-BR.js'
import '@chenfengyuan/datepicker/i18n/datepicker.ko-KR.js'
import '@chenfengyuan/datepicker/i18n/datepicker.zh-CN.js'

if (location.pathname.startsWith('/weboffice/statistics') || location.pathname.startsWith('/admin/statistics')) {
  const isAdmin = location.pathname.includes('admin')

  $('input.datepicker').datepicker({
    language: mainState.languageCode,
    format: 'yyyy.mm.dd',
    pick: (e) => {
      if (e.view !== 'day') {
        return
      }

      const params = {
        from: $('[name="start-date"]')[0].value,
        to: $('[name="end-date"]')[0].value
      }

      params[e.target.getAttribute('name') === 'start-date' ? 'from' : 'to'] = $(e.target).datepicker('getDate', true)

      window.location = `${baseUrl}/${isAdmin ? 'admin' : 'weboffice'}/statistics?${toURI(params)}`
    }
  })

  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './weboffice/statistics.js'
  )
} else if (location.pathname.startsWith('/allvoucher/')) {
  $('input.datepicker').datepicker({
    language: mainState.languageCode,
    format: 'yyyy.mm.dd'
  })
} else if (location.pathname.startsWith('/profile/uploadvoucher')) {
  const startDate = new Date()
  const endDate = new Date()
  startDate.setFullYear(startDate.getFullYear() + 1)
  endDate.setFullYear(endDate.getFullYear() + 3)

  $('input.datepicker').datepicker({
    language: mainState.languageCode,
    format: 'yyyy.mm.dd',
    startDate,
    endDate,
    pick: function (e) {
      e.target.value = moment(e.date).format('YYYY.MM.DD')
      e.target.dispatchEvent(new Event('change'))
    },
    hide: function (e) {
      const nextYear = moment().add(1, 'y')
      if (!e.target.value || !moment(e.target.value).isValid() || !moment(e.target.value).isAfter(nextYear)) {
        e.target.value = nextYear.format('YYYY.MM.DD')
      } else {
        e.target.value = moment(e.target.value).format('YYYY.MM.DD')
      }
      e.target.dispatchEvent(new Event('change'))
    }
  })
} else if (location.pathname.startsWith('/profile/myprofile')) {
  const date = new Date()

  $('input.datepicker').datepicker({
    language: mainState.languageCode,
    format: 'yyyy.mm.dd',
    endDate: date
  })
}

// TODO: a migrate mappán kívüli jQuery más instance, mint ez az importált, a bootstrap-es dolgok
// csak az importált jQuery-n vannak. ezt majd megoldja az, ha minden js itt lesz a migrate-ben
window._jQuery = $

if (location.pathname.startsWith('/admin/translation')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './admin/translation.js'
  )
}

console.log('Location Pathname', location.pathname)

if (location.pathname.startsWith('/admin')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './admin/index.js'
  )
}

if (location.pathname.startsWith('/profile/myprofile')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './profile/index.js'
  )
}

if (location.pathname.startsWith('/registration') && !location.pathname.includes('complete')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './profile/index.js'
  )
}

if (location.pathname.startsWith('/short-registration')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './profile/index.js'
  )
}

if (location.pathname.startsWith('/profile/uploadphoto')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './profile/uploadphoto.js'
  )
}

if (location.pathname.startsWith('/weboffice/foradvertisingletters')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './profile/sendletter.js'
  )
}

if (location.pathname.startsWith('/profile/uploadvoucher')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './profile/uploadphoto.js'
  )
}

if (location.pathname.startsWith('/voucher')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './photo.js'
  )
}

if (location.pathname.startsWith('/forgot')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './forgot.js'
  )
}

if (location.pathname.startsWith('/resend') || location.pathname.startsWith('/registration-complete')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './resend.js'
  )
}

if (location.pathname.startsWith('/weboffice/yourusers')) {
  import(
    /* webpackMode: "lazy", webpackChunkName: "migrate" */
    './weboffice/yourusers.js'
  )
}

const showQRCodeModal = (url, title = '') => {
  let modalDiv = document.getElementById('qrcode-modal')

  if (!modalDiv) {
    const template = document.createElement('template')
    template.innerHTML = `
      <div id="qrcode-modal" class="modal" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" style="padding:10px">${title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span>&times;</span>
              </button>
            </div>

            <div id="qrcode"></div>
            <a class="link" href="${url}">${url}</a>
          </div>
        </div>
      </div>
    `
    modalDiv = template.content.querySelector('#qrcode-modal')
    modalDiv.querySelector('.modal-title').textContent = title
    modalDiv.querySelector('.link').setAttribute('href', url)
    modalDiv.querySelector('.link').textContent = url
    document.body.appendChild(template.content)

    // eslint-disable-next-line no-new
    new QRCode(modalDiv.querySelector('#qrcode'), url)
  }

  $(modalDiv).modal('show')
}

window.showQRCodeModal = showQRCodeModal

document.querySelectorAll('.link-with-qrcode').forEach((wrapper) => {
  const url = wrapper.querySelector('a').getAttribute('href')

  // eslint-disable-next-line no-new
  new QRCode(wrapper.querySelector('.qrcode'), {
    text: url,
    width: 180,
    height: 180
  })
})

$('[data-toggle="switch"]').bootstrapSwitch()

const loginForm = document.getElementById('form_login')
if (loginForm) {
  loginForm.addEventListener('submit', async (e) => {
    e.preventDefault()

    const form = e.target
    form.disabled = true

    const submit = form.querySelector('button[type="submit"]')
    submit.disabled = true

    const spinner = form.querySelector('.loading')
    spinner.classList.remove('hidden')

    try {
      const { redirectTo } = await xhrPost(`${baseUrl}/login`, formToJSON(form))
      window.location.href = redirectTo
    } catch (e) {
      spinner.classList.add('hidden')
      form.disabled = false
      submit.disabled = false
      const error = await e.json()
      const errorMessage = join('\n', pluck('message', error.messages))
      notify(i18n.t('popup_title_login_failed'), errorMessage, 'warning')
    }
  })
}

/*
if (location.pathname.startsWith(`/registration`) && !location.pathname.startsWith('/registration-complete')) {
  document.querySelector('[href="#register"]').click()
  document.querySelector('.open-login').click()
}
*/

if (location.pathname.startsWith(`/login`)) {
  document.querySelector('[href="#login"]').click()
  document.querySelector('.open-login').click()
}

document.querySelectorAll('.trigger-fullpage-loader-on-click').forEach((btn) => {
  btn.addEventListener('click', () => {
    showFullpageLoading()
  })
})

const myUsersTree = document.getElementById('myusers-tree')
if (myUsersTree) {
  const buttons = myUsersTree.querySelectorAll('.expand-children')
  buttons.forEach((btn) => {
    btn.addEventListener('click', () => {
      const children = btn.closest('.user-row').nextSibling
      if (btn.classList.contains('opened')) {
        btn.classList.remove('opened')
        children.classList.remove('opened')
      } else {
        btn.classList.add('opened')
        children.classList.add('opened')
      }
    })
  })
}

const registerForm = document.getElementById('form_register')
if (registerForm) {
  Array.from(registerForm.querySelectorAll('[name]')).forEach((input) => {
    input.addEventListener('focus', () => {
      InputSetValidity(input, null, null)
    })
  })
  const showLoading = () => {
    registerForm.querySelector('.loading').classList.remove('hidden')
  }
  const hideLoading = () => {
    registerForm.querySelector('.loading').classList.add('hidden')
  }

  const hideLoginPanel = () => {
    document.querySelector('.login-wrapper').classList.remove('open')
  }

  const showRegistrationFeedback = () => {
    document.getElementById('registration-success').classList.remove('hidden')
  }

  const hideMainContent = () => {
    document.body.querySelectorAll('.wrapper>section').forEach((section) => {
      section.style.display = 'none'
    })
  }

  registerForm.addEventListener('submit', (e) => {
    e.preventDefault()

    showLoading()

    // forrás: https://developers.google.com/recaptcha/docs/v3#programmatically_invoke_the_challenge
    grecaptcha.ready(function () {
      // az execute csak egy objektumot ad vissza, amiben then és catch kulcsok vannak, nem valós Promise-t
      grecaptcha
        .execute('6LeHN3MmAAAAAH3w6lmfLUOlIyjFLAL_rHsDHPNC', {
          action: 'pgp2020register'
        })
        .then(async (token) => {
          try {
            const data = formToJSON(document.getElementById('form_register'))
            data['g-recaptcha-response'] = token
            await xhrPost(`${baseUrl}/register`, data)
            document.getElementById('form_register').reset()
            hideMainContent()
            showRegistrationFeedback()
            hideLoading()
            hideLoginPanel()
          } catch (e) {
            const error = await e.json()
            const messages = filter(has('field'), error.messages)

            Array.from(registerForm.querySelectorAll('[name]')).forEach((input) => {
              const message = find(propEq('field', input.getAttribute('name')), messages)
              const isValid = isNil(message)
              InputSetValidity(input, isValid, isValid ? null : message.message)
            })

            notify(i18n.t('popup_title_profile_not_saved'), i18n.t('popup_register_errors_in_the_form'), 'error')
            hideLoading()
          }
        })
        .catch((e) => {
          console.error(e)
          hideLoading()
        })
    })
  })

  document.getElementById('switch-acceptterm').addEventListener('change', (e) => {
    document.getElementById('btnregistration').hidden = !e.target.checked
  })
}

// eslint-disable-next-line no-new
/*
new SmartBanner({
  daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
  daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
  // appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
  title: 'MARKET-UP',
  author: 'PG Professional Service LTD',
  button: 'VIEW',
  store: {
    // ios: 'On the App Store',
    android: 'In Google Play'
  },
  price: {
    // ios: 'FREE',
    android: 'FREE'
  }
  // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
  // , icon: '' // full path to icon image if not using website icon image
  // , force: 'ios' // Uncomment for platform emulation
})
*/

const langSwitcher = () => {
  const btn = document.querySelector('.nav-settings')
  const cls = 'is-expanded'

  if (!btn) {
    return
  }

  btn.addEventListener('click', () => {
    if (btn.classList.contains(cls)) {
      btn.classList.remove(cls)
    } else {
      btn.classList.add(cls)
    }
  })

  document.addEventListener('click', function (event) {
    if (event.target.closest('.nav-settings')) {
      return
    }
    btn.classList.remove(cls)
  })
}

langSwitcher()

export const setIsLoading = (state, { isLoading }) => {
  return { ...state, isLoading }
}

export const setIsUnderPayment = (state, { isUnderPayment }) => {
  return { ...state, isUnderPayment }
}

export const setItems = (state, { items }) => {
  const { onephotocart, photocart, photossectorcart, sectorcart, servicecart } = items

  return {
    ...state,
    items: {
      services: servicecart || [],
      sectors: sectorcart || [],
      photosForService: photocart || [],
      photosForSector: photossectorcart || [],
      photos: onephotocart || []
    }
  }
}

export const setSettings = (state, { settings }) => {
  return {
    ...state,
    settings
  }
}

/* global i18n, eventBus, VERSION, HASH */

import 'core-js'
import { isPlainObject } from 'ramda-adjunct'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/store.js'

import './migrate/css/index.scss'
import './migrate/js/index.js'

const waitForI18nToLoad = (fn) => {
  if (i18n.loaded) {
    fn()
  } else {
    eventBus.once('system:translations-loaded', fn)
  }
}

document.querySelectorAll('[id^="react--"]').forEach(async (wrapper) => {
  const [componentType, componentName] = wrapper.getAttribute('id').replace('react--', '').split('--')
  const renderMode = wrapper.getAttribute('data-render-mode') === 'hydrate' ? 'hydrate' : 'render'

  let props = {}

  try {
    props = JSON.parse(wrapper.getAttribute('data-props'))
    if (!isPlainObject(props)) {
      props = {}
    }
  } catch (e) {}

  switch (componentName) {
    case 'PageEnd':
      props.version = VERSION
      props.hash = HASH
      break
  }

  try {
    // https://webpack.js.org/api/module-methods/#import-1
    const module = await import(
      /* webpackMode: "lazy-once", webpackChunkName: "dynamic" */
      `./${componentType}s/${componentName}/${componentName}.jsx`
    )
    const Component = module.default

    const content = (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    )

    waitForI18nToLoad(() => {
      if (renderMode === 'hydrate') {
        ReactDOM.hydrate(content, wrapper)
      } else {
        ReactDOM.render(content, wrapper)
      }
    })
  } catch (e) {
    console.log('Nem sikerült betölteni egy React komponenst', e)
  }
})

document.querySelectorAll('[type="password"]:not([hidden])').forEach((passwordField) => {
  const wrapper = document.createElement('label')
  wrapper.classList.add('password-field')

  passwordField.parentNode.appendChild(wrapper)
  wrapper.appendChild(passwordField)

  const visibleIcon = document.createElement('i')
  visibleIcon.classList.add('fas', 'fa-eye', 'hidden')
  wrapper.appendChild(visibleIcon)

  const hiddenIcon = document.createElement('i')
  hiddenIcon.classList.add('fas', 'fa-eye-slash')
  wrapper.appendChild(hiddenIcon)

  hiddenIcon.addEventListener('click', () => {
    hiddenIcon.classList.add('hidden')
    visibleIcon.classList.remove('hidden')
    passwordField.setAttribute('type', 'text')
  })
  visibleIcon.addEventListener('click', () => {
    visibleIcon.classList.add('hidden')
    hiddenIcon.classList.remove('hidden')
    passwordField.setAttribute('type', 'password')
  })
})

/* Input validation */
const validationPatterns = {
  noSpecialChars: /[^a-zA-Z0-9\-_]/g
}

const noSpecialChars = () => {
  const els = document.querySelectorAll('[data-validation="no-special-chars"]')

  if (els.length === 0) {
    return
  }

  const pattern = validationPatterns.noSpecialChars

  const handlerCurrent = (event) => {
    const pressedKey = event.key || String.fromCharCode(event.keyCode || event.which)

    if (pattern.test(pressedKey)) {
      event.preventDefault()
    }
  }

  const handler = (event) => {
    const str = event.target.value

    if (pattern.test(str)) {
      event.target.value = str.replace(pattern, '')
    }
  }

  ;[].forEach.call(els, (el) => {
    el.addEventListener('keydown', handlerCurrent)
    el.addEventListener('input', handler)
    el.addEventListener('change', handler)
    el.addEventListener('paste', handler)
  })
}

const inputValidation = () => {
  noSpecialChars()
}

inputValidation()

/* Blog Name Data Bind */
function BlogNameDataBind() {
  const els = document.querySelectorAll('[data-blog-name-data-bind]')

  if (els.length === 0) {
    return
  }

  const handler = (event) => {
    const elBind = document.getElementById(event.target.dataset.blogNameDataBind)
    const url = elBind.value.substring(0, elBind.value.lastIndexOf('/') + 1)
    if (event.target.value.trim().length === 0) {
      elBind.value = url + document.querySelector('[name="username"]').value + '-blog'
    } else {
      elBind.value = url + event.target.value
    }
  }

  ;[].forEach.call(els, (el) => {
    el.addEventListener('input', handler)
    el.addEventListener('change', handler)
  })
}

BlogNameDataBind()

export const setIsLoading = (state, { isLoading }) => {
  return { ...state, isLoading }
}

export const setServices = (state, { services }) => {
  return { ...state, services }
}

export const setSelectedServiceId = (state, { selectedServiceId }) => {
  return { ...state, selectedServiceId }
}

import { createStore, combineReducers } from 'redux'
import HistorySoldReducer from './HistorySold/reducer.js'
import BlogPostsReducer from './BlogPosts/reducer.js'
import MyPhotosReducer from './MyPhotos/reducer.js'
import CartReducer from './Cart/reducer.js'
import DevReferralsReducer from './DevReferrals/reducer.js'
import DevSystemSettingsReducer from './DevSystemSettings/reducer.js'
import ActivationReducer from './Activation/reducer.js'
import ViewPhotoReducer from './ViewPhoto/reducer.js'

const store = createStore(
  combineReducers({
    HistorySold: HistorySoldReducer,
    BlogPosts: BlogPostsReducer,
    MyPhotos: MyPhotosReducer,
    Cart: CartReducer,
    DevReferrals: DevReferralsReducer,
    DevSystemSettings: DevSystemSettingsReducer,
    Activation: ActivationReducer,
    ViewPhoto: ViewPhotoReducer
  })
)

export default store
